/*------Общие стили для главного контента домашней страницы----------------------------------------------------------*/

#root {
  height: 100%;
}

.App {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.main {
  overflow: hidden;
}

.main-content {
  position: relative;
}