/*------Контейнер для страницы регистрации-------------------------------------------------------------------------*/
.wrapper-reg {
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    overflow: hidden;
    font-size: 18px;
}
.container-reg {
    max-width: 1280px;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: content-box;
}
.reg-form__validate-error {
    display: none;
}

.reg-form__error-submit-phone {
    position: absolute;
    font-size: 12px;
    color: #FF4970;
    bottom: 45px;
    right: 270px;
}
.reg-block {
    display: flex;
    flex-direction: row;
    position: relative;
}
a {
    text-decoration: underline;
}
/*------Модификаторы для скрытия элементов при адаптиве под мобильные устройства---------------------------------------*/
._reg-block-show {
    display: inherit;
}

._reg-block-hidden {
    display: none;
}
.reg-form__title-row-icon {
    display: none;
}
.reg-form__title-row-icon.active {
    display: initial;
    cursor: pointer;
}
/*------Блок c логотипом и кнопкой--------------------------------------------------------------------------------------*/
.reg-block-logotype {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: #0084FE;
    padding: 74px 32px 74px 32px;
    width: 336px;
    max-height: 788px;
    -webkit-border-top-left-radius: 8px;
    -webkit-border-bottom-left-radius: 8px;
}
.reg-block-logotype__logo {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    font-size: 32px;
    gap: 9px;

    .reg__logo-main {
        margin-left: 0;
    }
}
.reg-block-logotype__logo-main {
    /*width: 223px;*/
    /*height: 28px;*/
    align-self: flex-end;
}
.reg-block-logotype__logo-second {
    width: 112px;
    height: 14px;
    align-self: flex-end;
}
.reg-block-logotype__title {
    margin-top: auto;
    text-align: left;
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
}

.reg-block-logotype__button {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    &.mobile {
        display: none;
    }
}
.reg-block-logotype__button span {
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}
.reg-block-logotype__button button {
    cursor: pointer;
    width: 116px;
    height: 48px;
    background-color: #FFFFFF;
    border-radius: 4px;
    color: #212140;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    z-index: 2;
}
/*------Блок с формой регистрации--------------------------------------------------------------------------------------*/
.reg-block__reg-form {
    display: flex;
    flex-direction: column;
    background-color: #FFFF;
    padding: 65px 56px;
    width: 768px;
    max-height: 788px;
    font-size: 18px;
    -webkit-border-top-right-radius: 10px;
    -webkit-border-bottom-right-radius: 8px;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
    color: rgba(54, 59, 77, 0.9);
    gap: 32px;
}
.reg-form__title {
    display: inline-flex;
    align-items: center;
    /*justify-content: space-between;*/
    color: rgba(54, 59, 77, 0.9);
    gap: 19px;
}
.reg-form__title h3 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: rgba(54, 59, 77, 0.9);
}
.reg-form__title span {
    padding-right: 8px;
    font-size: 16px;
    cursor: pointer;
}
.reg-form__title span:last-child {
    font-size: 16px;
    color: #0084FE;
    text-decoration: underline;
}
/*------Форма регистрации ФИО-------------------------------------------------------------------------------------------*/
.reg-form__username {
    display: grid;
    column-gap: 14px;
    grid-template-columns: repeat(3, 1fr);

    color: #363B4D;
}
.reg-form__username.active {
    display: none;
}
.reg-form__username input {
    width: 208px;
    height: 48px;
    /*border: 1px solid rgba(54, 59, 77, 0.5);*/
    padding: 5px 24px;
    border-radius: 8px;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
}
.username-forms {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}
/*.username-forms span {*/
/*    position: relative;*/
/*}*/
.username-forms__red-star-icon__family {
    position: absolute;
    width: 8px;
    height: 8px;
    cursor: pointer;
    top: 0;
    left: 89px;
}
.username-forms__red-star-icon__name {
    position: absolute;
    width: 8px;
    height: 8px;
    cursor: pointer;
    top: 0;
    left: 42px;
}
/*------Форма регистрации E-mail--------------------------------------------------------------------------------------*/
.reg-form__e-mail {
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 16px;
}
.reg-form__e-mail.active {
    display: flex;
}
.reg-form__e-mail input {
    width: 100%;
    height: 48px;
    /*border: 1px solid rgba(54, 59, 77, 0.5);*/
    padding: 5px 24px;
    border-radius: 8px;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
}
.reg-form__e-mail span {
    position: relative;
}
.reg-form__e-mail__red-star-icon{
    position: absolute;
    width: 8px;
    height: 8px;
    cursor: pointer;
    top: 0;
    left: 58px;
}
/*------Форма регистрации телефон--------------------------------------------------------------------------------------*/
.reg-form__select-email-phone-container {
    line-height: 154%;
}
.reg-form__select-email-phone {
    font-size: 18px;
    background: none;
    outline: none;
    cursor: pointer;
    color: rgba(54, 59, 77, 0.9);
}
.reg-form__select-email {
    color: rgba(54, 59, 77, 0.9);
    font-size: 16px;
}
.reg-form__select-phone {
    color: rgba(54, 59, 77, 0.9);
    font-size: 16px;
}
.reg-form__input-phone-block {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 16px;
    position: relative;
}
.reg-form__input-phone-block input {
    /*max-width: 400px;*/
}
.reg-form__input-phone-block-btn {
    border-radius: 8px;
    font-size: 12px;
    color: rgba(54, 59, 77, 0.9);
    border: 1px solid rgba(54, 59, 77, 0.3);
    background: none;
    cursor: pointer;
    padding: 0 8px 0 8px;
}
.reg-form__input-phone-block-confirm-sms {
    color: rgba(54, 59, 77, 0.9);
    border: 1px solid rgba(54, 59, 77, 0.3);
    width: 30px;
}
.reg-form__input-phone-block-confirm-sms.hidden {
    display: none;
}
.reg-form__confirm-sms-message {
    position: absolute;
    font-size: 12px;
    bottom: -16px;
    color: #4ED4A9;
}.reg-form__confirm-sms-message.hidden {
     display: none;
 }
.reg-form__input-phone-block-error {
    position: absolute;
    font-size: 12px;
    color: #FF4970;
    bottom: -16px;
    right: 0;
}
.reg-form__label-select {
    font-size: 1em;
    opacity: 0.9;
}
/*------Форма регистрации пароля--------------------------------------------------------------------------------------*/
.reg-form__password {
    display: grid;
    position: relative;
    column-gap: 13px;
    grid-template-columns: repeat(2,1fr);

}
.reg-form__password.active {
    display: flex;
}
.reg-form__password input {
    width: 320px;
    height: 48px;
    /*border: 1px solid rgba(54, 59, 77, 0.5);*/
    padding: 5px 50px 5px 24px;
    border-radius: 8px;
    outline: none;
    font-size: 18px;
    line-height: 24px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
}
.password-form {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}
.password-form span {
    position: relative;
    opacity: 0.9;
}
.reg-form__password__red-star-icon {
    position: absolute;
    width: 8px;
    height: 8px;
    cursor: pointer;
    top: 0;
    left: 182px;
}
.reg-form__password__red-star-icon-repeat-pass {
    position: absolute;
    width: 8px;
    height: 8px;
    cursor: pointer;
    top: 0;
    left: 170px;
}
.reg-form__show-pass-icon {
    position: absolute;
    width: 22px;
    height: 15px;
    cursor: pointer;
    top: 50px;
    right: 22px;
}
.reg-form__hidden-pass-icon {
    position: absolute;
    width: 22px;
    height: 19px;
    cursor: pointer;
    top: 48px;
    right: 22px;
}
/*------Форма выбора часового пояса-------------------------------------------------------------------------------------*/
/* Переделал */
.reg-form__time-zone-main-container {
    display: flex;
    flex-direction: column;
    margin: 0 auto 0 0;
    @media (max-width: 767px) {
        margin-right: 0;
        margin-bottom: 16px;
    }
}
.reg-form__time-zone-main-container.active {
    display: none;
}
.reg-form__time-zone-heading {
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: 0 auto 0 0;
}
.reg-form__time-zone-heading_span {
    color: #FF4970;
}
.reg-form__time-zone-select-container {
    display: flex;
    position: relative;
    width: 320px;
    height: 48px;
    margin: 16px auto 0 0;
    border: 0.5px solid rgba(54, 59, 77, 0.3);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}
.reg-form__time-zone-select-value {
    font-size: 16px;
    line-height: 22px;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: auto auto auto 24px;
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.reg-form__time-zone-select-arrow {
    width: 16px;
    height: 16px;
    object-fit: cover;
    margin: auto 24px auto auto;
}
.reg-form__time-zone-options-container {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #FFFFFF;
    overflow-y: scroll;
    top: 58px;
    left: -1px;
    width: 320px;
    max-height: 200px;
    box-shadow: 0 4px 8px rgba(54, 59, 77, 0.1);
    border: 0.5px solid rgba(54, 59, 77, 0.2);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 0 24px 24px;
    cursor: default;
    z-index: 9;
}
.reg-form__time-zone-options-container::-webkit-scrollbar {
    width: 4px;
    background: #FFFFFF;
    border-radius: 4px;
}
.reg-form__time-zone-options-container::-webkit-scrollbar-thumb {
    background: #CFD4DD;
    border-radius: 4px;
}
.reg-form__time-zone-option {
    line-height: 24px;
    color: #363B4D;
    opacity: 0.9;
    margin: 24px auto 0 0;
}
.reg-form__time-zone-option:hover {
    cursor: pointer;
    opacity: 0.6;
}
.reg-form__select-time-zone {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}
.reg-form__select-time-zone select {
    background-color: white;
    position: absolute;
    left: 180px;
    top: 2px;
    cursor: pointer;
    outline: none;
    width: 60px;
}
.reg-form__select-time-zone span {
    z-index: 3;
}
.reg-form__select-time-zone__red-star-icon {
    display: none;
}
/*------Форма c чекбоксом---------------------------------------------------------------------------------------------*/
.reg-form__checkbox {
    display: flex;
    flex-direction: row;
    z-index: 8;
    margin: 0 auto 0 0;
}
.reg-form__checkbox.active {
    display: flex;
}
.reg-form__checkbox span {
    font-size: 18px;
    line-height: 156%;
    font-weight: 400;
    margin: 0 auto 0 0;
}
.reg-form__checkbox a {
    color: #0084FE;
}
/*------Кнопка зарегистрироваться--------------------------------------------------------------------------------------*/
.reg-form__button-btn-reg {
    cursor: pointer;
    width: 234px;
    height: 56px;
    background-color: #212140;
    border-radius: 4px;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    z-index: 5;
}
.reg-form__button.active {
    display: initial;
}
.reg-form__button-btn-reg:hover {
    background-color: #FFFFFF;
    color: #212140;
    border: 1px #212140 solid;
}
.reg-form__button-btn-reg.active {
    background-color: rgba(54, 59, 77, 0.08);
    border: none;
    cursor: default;
    color: #212140;
}
.reg-form__button span {
    color: rgba(54, 59, 77, 0.4);
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    margin: 0 auto;
    padding-bottom: 8px;
}

/*-------Блок с кнопкой Продожить для мобильной версии----------------------------------------------------------------*/
.reg-block__button-next-page {
    display: none;
}
.reg-block__button-next-page.active {
    display: none;
}
/*-------Бордер для input при ошибках в заполнении форм----------------------------------------------------------------*/
._input-border-black-reg-page {
    border: 1px solid rgba(54, 59, 77, 0.3);
}
._input-border-red {
    border: 1px solid red;
}
/*-------Предупреждения об ошибке в заполнении форм----------------------------------------------------------------------*/
.reg-block__error-message {
    position: absolute;
    top: 90px;
    right: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 143%;
    color: #FF4970;
    opacity: 0.9;
}
.reg-form__input-error {
    position: absolute;
    font-size: 12px;
    color: red;
    top: 88px;
}
/*------Адаптивная версия(1108px)--------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .container-reg {
        max-width: 800px;
    }
    .reg-block-logotype {
        width: 300px;
    }
    .reg-form__title h3 {
        font-size: 28px;
    }
    .reg-form__title span {
        font-size: 14px;
    }
    .reg-form__title a {
        font-size: 14px;
    }
    .reg-block__reg-form {
        width: 500px;
        padding: 25px 25px;
        gap: 25px;
    }
    .reg-block-logotype {
        padding: 35px 25px;
    }
    .reg-block-logotype__logo {
        padding-bottom: 465px;
        gap: 0;
    }
    .reg-block-logotype__logo-main {
        width: 180px;
        height: 28px;
        align-self: flex-end;
    }
    .reg-block-logotype__logo-second {
        width: 100px;
        height: 14px;
        align-self: flex-end;
    }
    .reg-form__username {
        grid-template-rows: 75px;
    }
    .reg-form__username input {
        width: 140px;
        height: 40px;
    }
    .username-forms, .reg-form__e-mail, .reg-form__password {
        font-size: 16px;
    }
    .reg-form__e-mail input {
        height: 40px;
    }
    .reg-form__select-email-phone {
        font-size: 16px;
    }
    .reg-form__select-email-phone-container {
        flex-wrap: nowrap;
        flex-direction: row;
    }
    .password-form input {
        width: 220px;
        height: 40px;
    }
    .reg-block-logotype__title {
        top: 460px;
        font-size: 20px;
        left: 22px;
        line-height: 24px;
    }
    .reg-form__checkbox span {
        font-size: 14px;
    }
    .reg-form__select-time-zone span {
        font-size: 16px;
    }
    .reg-block__error-message {
        top: 85px;
        font-size: 12px;
    }
    .reg-form__select-time-zone::after {
        right: 230px;
    }
    .reg-form__show-pass-icon {
        top: 44px;
    }
    .reg-form__hidden-pass-icon {
        top: 42px;
    }
    .reg-form__input-error {
        top: 78px;
    }
    .reg-form__select-email {
        font-size: 14px;
    }
    .reg-form__select-phone {
        font-size: 14px;
    }
    .reg-form__error-submit-phone {
        bottom: 45px;
        right: 40px;
    }
}
@media (max-height: 780px) {
    .wrapper-reg {
        overflow-y: auto;
    }
}
/*------Адаптивная версия(c 805px до 375px)----------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .container-reg {
        position: relative;
        width: 380px;
        height: 100%;
    }
    .reg-block__reg-form {
        position: absolute;
        top: 70px;
        left: 0;
        padding: 24px 16px 24px 16px;
        font-size: 16px;
        width: 375px;
        border-radius: 16px;
        gap: 16px;
        height: 612px;
    }
    .reg-form__title h3 {
        font-size: 26px;
        margin-right: 40px;
    }
    .reg-block-logotype {
        border-radius: 16px;
        width: 375px;
        height: 667px;
    }
    .reg-block-logotype__title {
        display: none;
    }
    .reg-block-logotype__logo img {
        /*position: absolute;*/
        /*top: 25px;*/
        /*left: 17px;*/
        /*width: 197px;*/
        /*height: 33px;*/
    }
    .reg-block-logotype__logo {
        width: 100%;
        padding: 20px 30px 0 15px;
    }
    .reg-block-logotype__logo-main {
        width: 107px;
        height: 25px;
        align-self: flex-start;
    }
    .reg-block-logotype__logo-second {
        width: 70px;
        height: 8px;
        align-self: flex-end;
    }
    .reg-block-logotype__button {

        font-size: 16px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        z-index: 1;
        color: #0084FE;
        gap: 6px;
        &:not(.mobile) {
            display: none;
        }

        &.mobile {
            display: flex;
        }
    }
    .reg-form__title span:last-child {
        font-size: 14px;
    }
    .reg-block-logotype__button span {
        color: rgba(54, 59, 77, 0.9);
        font-weight: 400;
        height: 24px;
        vertical-align: center;
    }
    .reg-block-logotype__button button {
        width: 47px;
        height: 24px;
    }
    .reg-form__username {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 16px;
        margin-bottom: 16px;
    }
    .username-forms {
        gap: 10px;
    }
    .username-forms input {
        width: 100%;
        height: 48px;
        padding: 10px 16px 10px 16px;
        font-size: 16px;
    }
    .reg-form__select-time-zone select {
        top: 24px;
        left: 0;
        width: 100%;
        height: 48px;
        border: 1px solid rgba(54, 59, 77, 0.3);
        border-radius: 8px;
        font-size: 14px;
        padding: 10px 16px 10px 16px;
    }
    .reg-form__select-time-zone::after {
        top: 40px;
        right: 23px;
    }
    .reg-form__select-time-zone__red-star-icon {
        display: inherit;
        position: absolute;
        top: 0;
        left: 190px;
    }
    .username-forms__red-star-icon__family {
        left: 78px;
    }
    .username-forms__red-star-icon__name {
        left: 38px;
    }
    .reg-form__password__red-star-icon {
        left: 165px;
    }
    .reg-form__password__red-star-icon-repeat-pass {
        left: 150px;
    }
    .reg-form__select-time-zone::after {
        top: 40px;
        right: 23px;
    }
    .reg-block__button-next-page {
        display: flex;
        flex-direction: column;

        text-align: center;

        gap: 8px;
    }
    .reg-block__button-next-page span {
        color: rgba(54, 59, 77, 0.4);
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
    .reg-block__button-next-page button {
        background-color: #212140;
        border-radius: 4px;
        width: 100%;
        height: 48px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;

    }
    .auth-image_1, .auth-image_2, .auth-image_3, .auth-image_4, .auth-image_5, .auth-image_6 {
        display: none;
    }
    .reg-form__e-mail {
        gap: 10px;
    }
    .reg-form__e-mail input {
        width: 100%;
        height: 48px;
        padding: 10px 16px 10px 16px;
    }
    .reg-form__password {
        display: flex;
        flex-direction: column;
        gap: 19px;
    }
    .reg-form__password input {
        width: 100%;
        height: 48px;
        padding: 10px 45px 10px 16px;
    }
    .password-form {
        gap: 10px;
    }
    .reg-form__show-pass-icon, .reg-form__hidden-pass-icon {
        top: 40px;
    }
    .reg-form__checkbox {
        padding-bottom: 0;

    }
    .reg-form__checkbox span {
        font-size: 16px;
        line-height: 22px;
    }
    .reg-form__checkbox a {
        text-decoration: none;
    }
    .reg-form__button {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        gap: 8px;
    }
    .reg-form__button span {
        color: rgba(54, 59, 77, 0.4);
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
    }
    .reg-form__button-btn-reg {
        width: 100%;
        height: 48px;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
    }
    .reg-form__time-zone-select-container {
        width: 100%;
        height: 48px;
        margin: 8px auto 0 0;
    }
    .reg-form__time-zone-select-value {
        margin: auto auto auto 16px;
        max-width: 285px;
    }
    .reg-form__time-zone-select-arrow {
        margin: auto 16px auto auto;
    }
    .reg-form__time-zone-options-container {
        left: -2px;
        width: 345px;
        max-height: 150px;
        padding: 0 16px 16px;
    }
    .reg-form__time-zone-option {
        margin: 16px auto 0 0;
    }
    ._reg-block-show {
        display: none;
    }
    ._reg-block-hidden {
        display: inherit;
    }
    ._modificator-reg-form__title-padding-right {
        padding-right: 24px;
    }
    ._modificator-reg-block__error-message-mobile {
        top: 169px;
    }
    .reg-block__reg-form.active {
        display: none;
    }

    .main-block__auth.active {
        display: none;
    }
    ._modificator-reg-block-logotype__logo-top {
        position: absolute;
        top: 2px;
        left: 0;
    }
    .reg-form__input-error {
        top: 78px;
        font-size: 10px;
    }
    .reg-form__validate-error {
        display: block;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 200%;
        color: #FF4970;
        opacity: 0.9;
        margin: 0 auto;
    }
    .reg-block__error-message {
        top: 170px;
    }
    .reg-form__error-submit-phone {
        bottom: 70px;
        right: 100px;
    }
    .reg-form__select-email-phone {
        font-size: 16px;
    }
    .auth-form__select-email-phone-container {
        /*height: 12px;*/
    }
    .reg-form__select-email {
        font-size: 12px;
    }
    .reg-form__select-phone {
        font-size: 12px;
    }
}
@media (max-width: 767px) {
    .reg-form__select-email {
        font-size: 16px;
    }
    .reg-form__select-phone {
        font-size: 16px;
    }
    .reg-form__select-email-phone {
        font-size: 16px;
    }
    .auth-form__select-email-phone-container {
        height: 16px;
    }
}
/*------Адаптивная версия(372px)--------------------------------------------------------------------------------------*/
@media (max-width: 420px) {
    .container-reg {
        overflow: auto;
    }
    .reg-block {
        width: 21em;
    }
    .reg-block__button-next-page {
        right: 50px;
    }
}