/*---------Cтилизация заголовка--------------------------------------------------------------------------------------*/
.active-votes__title {
    display: flex;
    flex-direction: column;
    gap: 3px;
}
.active-votes__title h2 {
    font-size: 20px;
    color: rgba(54, 59, 77, 0.9);
    line-height: 110%;
    padding-bottom: 12px;
    width: 760px;
}
.active-votes__title h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.6);
    line-height: 24px;
    padding-bottom: 12px;
}
/*---------Cтилизация контейнера--------------------------------------------------------------------------------------*/
.active-votes__title-timezone {
    display: inline-flex;
    gap: 9px;
    font-size: 14px;
    font-weight: 400;
    line-height: 118%;
    color: rgba(54, 59, 77, 0.6);
}
/*---------Адаптивная версия------------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .active-votes__title h2 {
       font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        padding-bottom: 8px;
        width: 100%;
    }

}
@media (max-width: 767px) {
    .title-timezone__time-icon {
        display: none;
    }
    .active-votes__title h3 {
        padding-bottom: 4px;
    }
    .active-votes__title {
        gap: 4px;
    }
}