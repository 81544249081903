.checkmark-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-color: #0084FE;
    border-style: solid;
    border-width: 1px;
}
.checkbox__main-container {
    display: flex;
}
.checkbox__main-container-type-img {
    display: flex;
    align-items: center;
}
.checkbox_container {
    display: block;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    font-size: 18px;
    user-select: none;
    max-width: 10px;
}
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark-row {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #FFFFFF;
    border-color: #0084FE;
    border-style: solid;
    border-width: 1px;
}

.checkbox_container:hover input ~ .checkmark-row {
    background-color: #ccc;
}

.checkbox_container input:checked ~ .checkmark-row {
    background-color: #0084FE;
}

.checkmark-row:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox_container input:checked ~ .checkmark-row:after {
    display: block;
}
.checkbox_container .checkmark-row:after {
    left: 5px;
    top: 3px;
    width: 8px;
    height: 8px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.call-voting-list__name-answer {
    font-size: 18px;
    color: rgba(54, 59, 77, 0.9);
    font-weight: 400;
    line-height: 20px;
}
.call-voting-list__name-answer-img {
    object-fit: cover;
    width: 50%;
    height: 100%;
}
.call-voting-list__name-answer-index-img {
    line-height: 140%;
    font-size: 16px;
}
.call-voting-list__blue-square-container {
    display: flex;
    margin: 0 auto 0 0;
}
.call-voting-list__blue-square-container-type-img {
    display: flex;
    margin: 0 auto 0 0;
    align-items: center;
}
.call-voting-list__blue-square {
    width: 10px;
    height: 10px;
    background: #CCE4FF;
    margin: auto 12px auto 0;
}
.call-voting-list__text {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: auto auto auto 0;
}
/*---------Адаптивная версия-----------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .call-voting-list__name-answer {
        font-size: 16px;
    }
    .call-voting-list__name-answer-img {
        object-fit: cover;
        width: 90%;
        height: 100%;
    }
}