/*------Стилизация для div списка с вариантами ответов----------------------------------------------------------------*/
.card-list__list-answer {
    display: table;
    list-style-image: url("../../../img/DetailsVotesPageReadQuestions_icon_for_list.svg");
    padding-bottom: 16px;
}
.card-list__list-answer li {
    list-style-position: inside;
    font-size: 18px;
    line-height: 24px;
    color: rgba(54, 59, 77, 0.9);
}
.card-list__list-answer span {
    padding-left: 12px;
}
.card-list__name-answer-img {
    object-fit: cover;
    width: 50%;
    /*height: 100%;*/
    margin-bottom: 16px;
}
.card-list__checkbox-container {
    display: flex;
    margin: 0 auto 14px 0;
    align-items: center;
}
.card-list__checkbox-icon {
    width: 24px;
    height: 24px;
    margin: auto 16px auto 0;
}
.card-list__checkbox-value {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: rgba(54, 59, 77, 0.9);
    opacity: 0.9;
    margin: auto auto auto 0;
}
/*------Адаптивная версия(800px)---------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .card-list__list-answer {
        padding-bottom: 8px;
    }
    .card-list__list-answer li {
        font-size: 16px;
    }
    .card-list__name-answer-img {
        object-fit: cover;
        width: 90%;
        /*height: 100%;*/
        margin: 0 0 12px 0;
    }
}
