/*------Контейнер для блока------------------------------------------------------------------------------------------*/
.amount-votes-wrapper {
    display: flex;
    /*width: 656px;*/
    width: 100%;
    height: 280px;
    flex-direction: column;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 32px 52px 32px 32px;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
    gap: 16px;
}
.amount-votes-wrapper h3 {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
}
.amount-votes-wrapper h4 {
    font-size: 16px;
    line-height: 124%;
    font-weight: 400;
    color: rgba(54, 59, 77, 0.9);
    padding-bottom: 8px;
}
/*------Гистограмма и количество голосующих людей ежедневно------------------------------------------------------------*/
.amount-votes__gistogramma-and-total-amount {
    display: flex;
    position: relative;
    flex-direction: row;
    align-items: center;
    gap: 70px;
}
.gistogramma-and-total-amount__procent {
    position: absolute;
    top: 0;
    left: 310px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: #4ED4A9;
}

.gistogramma-and-total-amount__procent_negative {
	position: absolute;
	top: 0;
	left: 310px;
	font-size: 16px;
	font-weight: 400;
	line-height: 20px;
	color: #FF4970;
}
.gistogramma-and-total-amount__row {
    position: absolute;
    top: 4px;
    left: 287px;
}

.gistogramma-and-total-amount__row_negative {
	color: #FF4970;
	transform: scale(-1);
	position: absolute;
	top: 4px;
	left: 287px;
}

.total-amount {
    display: flex;
    flex-direction: column;
    color: rgba(54, 59, 77, 0.6);
    gap: 15px;
}
.total-amount h1 {
    font-weight: 900;
    font-size: 80px;
}
.total-amount span {
    font-size: 14px;
    font-weight: 400;
    line-height: 142%;
    white-space: nowrap;
}
/*------Адаптивная версия(830px)--------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .amount-votes-wrapper {
       width: 402px;
        /*height: 271px;*/
        padding: 16px 0 24px 16px;
    }
    .amount-votes-wrapper h3 {
      display: none;
    }
    .amount-votes-wrapper h4 {
        font-size: 21px;
        font-weight: 500;
        line-height: 29px;
        white-space: nowrap;
        padding-bottom: 0;
    }
    .amount-votes__gistogramma-and-total-amount {
        flex-direction: column-reverse;
        align-items: flex-start;
        gap: 10px;
    }
    .total-amount h1 {
        font-size: 42px;
    }
    .gistogramma-and-total-amount__procent, .gistogramma-and-total-amount__procent_negative {
        top: 75px;
    }
    .gistogramma-and-total-amount__row {
        top: 80px;
    }
}
/*------Адаптивная версия(420px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .amount-votes-wrapper {
        width: 100%;
        max-width: 800px;
        height: 271px;
    }
    .total-amount {
        gap: 4px;
    }
    .gistogramma-and-total-amount__procent, .gistogramma-and-total-amount__procent_negative {
        top: 65px;
        left: 310px;
        font-size: 14px;
    }
    .gistogramma-and-total-amount__row {
        top: 69px;
        left: 287px;
    }

}
@media (max-width: 420px) {
    .gistogramma-and-total-amount__procent, .gistogramma-and-total-amount__procent_negative {
        top: 65px;
        left: 268px;
        font-size: 14px;
    }
    .gistogramma-and-total-amount__row {
        top: 69px;
        left: 247px;
    }
}
@media (max-width: 420px) {
    .amount-votes-wrapper h4 {
        font-size: 18px;
        line-height: 124%;
        font-weight: 400;
        color: rgba(54, 59, 77, 0.9);
        padding-bottom: 8px;
    }
}
