/*------Основной контейнер--------------------------------------------------------------------------------------------*/
.details-votes-page-result-votes-card__wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.details-votes-page-result-votes-card__wrapper:first-child(1) {
    border: 1px solid red;

}
/*------Стилизация заголовка-------------------------------------------------------------------------------------------*/
.details-votes-page-result-votes-card__title {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.details-votes-page-result-votes-card__title h3 {
    font-size: 18px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    line-height: 24px;
}
.details-votes-page-result-votes-card__title h5 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    opacity: 0.4;
}
/*------Стилизация кнопок иконок---------------------------------------------------------------------------------------*/
.details-votes-page-result-votes-card__switch-table-gistogramma {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin: 0 auto 24px 0;
}
.switch-table-gistogramma__gistogramma {
    background: url("../../img/DetailsVotesPageResult_noactive_gistogram_icon.svg") no-repeat;
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.switch-table-gistogramma__table {
     background: url("../../img/DetailsVotesPageResult_noactive_table_icon.svg") no-repeat;
     cursor: pointer;
     width: 48px;
     height: 48px;

 }

 .switch-table-gistogramma__table2 {
    background: url("../../img/DetailsVotesPageResult_noactive_table2_icon.svg") no-repeat;
    cursor: pointer;
    background-size: 48px 48px;
    width: 48px;
    height: 48px;

}

 .switch-table-gistogramma__gistogramma:hover {
       background: url("../../img/DetailsVotesPageResult_active_gistogram_icon.svg");

 }
.switch-table-gistogramma__table:hover {
        background: url("../../img/DetailsVotesPageResult_active_table_icon.svg") no-repeat;

}

.switch-table-gistogramma__table2:hover {
    background: url("../../img/DetailsVotesPageResult_active_table2_icon.svg") no-repeat;
    background-size: 48px 48px;

}

.switch-table-gistogramma__gistogramma.active {
    background: url("../../img/DetailsVotesPageResult_active_gistogram_icon.svg");

    cursor: pointer;
}
.switch-table-gistogramma__table.active {
    background: url("../../img/DetailsVotesPageResult_active_table_icon.svg") no-repeat;
    width: 48px;
    height: 48px;
    cursor: pointer;
}

.switch-table-gistogramma__table2.active {
    background: url("../../img/DetailsVotesPageResult_active_table2_icon.svg") no-repeat;
    width: 48px;
    height: 48px;
    cursor: pointer;
    background-size: 48px 48px;
}


@media (max-width: 767px) {
    .switch-table-gistogramma__table2,
    .switch-table-gistogramma__table2:hover
    .switch-table-gistogramma__table2.active {
        background-size: 32px 32px !important;
    }
}

/*------Всплывающая подсказка для кнопок иконок-----------------------------------------------------------------------*/
.tooltip {
    position: relative;
    display: inline-block;
}
/* Текст подсказки */
.tooltip .tooltiptext {
    visibility: hidden;
    width: 159px;
    background: rgba(61, 72, 87, 0.9);
    color: #FFFFFF;
    text-align: center;
    padding: 4px 12px 4px 12px;
    border-radius: 2px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    /* Расположите текст подсказки */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -80px;
    /* Исчезают в подсказке */
    opacity: 0;
    transition: opacity 0.3s;
}
/* Подсказка стрелка */
.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}
/* Отображение текста подсказки при наведении курсора мыши на контейнер подсказки */
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
/*-----Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
    .details-votes-page-result-votes-card__wrapper {
        gap: 16px;
        padding-bottom: 16px;
        display: block;
    }
    .details-votes-page-result-votes-card__title {
        gap: 8px;
    }
    .details-votes-page-result-votes-card__title h3 {
        font-size: 16px;
        font-weight: 500;
    }
    .details-votes-page-result-votes-card__title h5 {
        font-size: 12px;
        line-height: 20px;
    }
    .switch-table-gistogramma__gistogramma {
        background: url("../../img/DetailsVotesPageResult_noactive_gistogram_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .switch-table-gistogramma__table {
        background: url("../../img/DetailsVotesPageResult_noactive_table_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .switch-table-gistogramma__gistogramma:hover {
        background: url("../../img/DetailsVotesPageResult_active_gistogram_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .switch-table-gistogramma__table:hover {
        background: url("../../img/DetailsVotesPageResult_active_table_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .switch-table-gistogramma__gistogramma.active {
        background: url("../../img/DetailsVotesPageResult_active_gistogram_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .switch-table-gistogramma__table.active {
        background: url("../../img/DetailsVotesPageResult_active_table_icon_mobile.svg") no-repeat;
        width: 32px;
        height: 32px;
    }
    .tooltip .tooltiptext {
        font-size: 10px;
        width: 120px;
        left: 150%;
        padding: 2px 2px;
    }
    .tooltip .tooltiptext::after {
        top: 100%;
        left: 70%;
        margin-left: -41px;
    }
}