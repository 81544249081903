/*------Модификатор изображения в блоке с лого-------------------------------------------------------------------------*/
._modificator-auth-image-mobile-height {
    height: 535px;
    left: 77px;
}
/*------Модификатор логотипа -----------------------------------------------------------------------------------------*/
._modificator-reg__logo-padding-bottom {
    padding-bottom: 337px;
}
/*-------Модификатор блока с заголовком Забыли пароль------------------------------------------------------------------*/
._modificator-reg__title-top {
    top: 396px;
}
/*------Модификатор основного блока------------------------------------------------------------------------------------*/
._modificator-main-block__auth-padding {
    padding: 106px 56px 106px 60px;
}
.auth-forget__email-toggle {
    cursor: pointer;
    text-decoration:  underline;
}
.auth-forget__email-toggle.active {
   color: #0084FE;
}
.auth-forget__email-toggle:hover {
    color: #0084FE;
}
.auth-forget__phone-toggle {
    cursor: pointer;
    text-decoration:  underline;
}
.auth-forget__phone-toggle.active {
    color: #0084FE;
}
.auth-forget__phone-toggle:hover {
    color: #0084FE;
}
/*------Модификатор блока с логотипом и изображением--------------------------------------------------------------------------------------*/
._modificator-main-block__reg-padding {
    padding: 82px 32px 106px 36px;
}
/*------Модификатор заголовка Система элетронных голосований-------------------------------------------------------------*/
._modificator-auth__title-padding-left {
    gap: 16px;
}
/*------Cтилизация к описанию формы e-mail---------------------------------------------------------------------------*/
.auth__description {
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}
/*------Стрелка для возврата на страницу авторизации------------------------------------------------------------------*/
.auth__link-row-icon {

    width: 24px;
    height: 16px;

}
.main-block__auth.active {
    display: none;
}
/*-----Стилизация блока с input email --------------------------------------------------------------------------------*/
.form__e-mail {
    display: flex;
    flex-direction: column;
    position: relative;
}
/*------Стилизация поля ввода email-----------------------------------------------------------------------------------*/
.form__e-mail input {
     border-radius: 8px;
     opacity: 50%;
     width: 100%;
     height: 56px;
     padding: 5px 0 5px 10px;
     font-size: 18px;
     font-weight: 400;
     line-height: 24px;
     outline: none;
     border: 1px solid rgba(54, 59, 77, 0.6);
}
.form__e-mail-error {
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #FF4970;
    opacity: 0.9;
    margin: 4px 0 0 auto;
    position: absolute;
    bottom: 30px;
    right: 0;
}
/*------Стилизация кнопки Отправить-----------------------------------------------------------------------------------*/
.auth__button button {
    width: 175px;
    height: 56px;
    border-radius: 4px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 133%;
    background-color: #0084FE;
    cursor: pointer;
}
.auth__button button:hover {
    background-color: #FFFFFF;
    color: #0084FE;
    border: 1px #0084FE solid;
}

/*------Стилизация кнопки Готово---------------------------------------------------------------------------------------*/
.button-ready-mobile {
    display: none;
    height: 48px;
    width: 100%;
    background-color: #0084FE;
    padding: 12px 145px 12px 145px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 24px;
    border-radius: 4px;
    font-weight: 500;
    cursor: pointer;
}

/*------Адаптивная версия(800px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    ._modificator-main-block__auth-padding {
        padding: 24px 16px 24px 16px;
        top: 306px;
    }

    .auth__link-row-icon {
        top: 35px;
        z-index: 5;
    }
    .auth__description {
        font-size: 16px;
        line-height: 26px;
    }

    .form__e-mail input {
        width: 100%;
        height: 48px;
    }
    ._modificator-main-block__height {
        height: 400px;
    }
    ._modificator-main-block__reg-padding {
        padding: 20px 20px 20px 20px;
    }
    .auth__button {
        display: none;
    }
    .button-ready-mobile {
        display: inherit;
    }
    .form__e-mail-error {
        bottom: 6px;
    }
}
/*------Адаптивная версия(375px)--------------------------------------------------------------------------------------*/
@media (max-width: 420px) {
    ._modificator-main-block__height {
        height: 646px;
    }

}