/*------Основной стиль для header-------------------------------------------------------------------------------------*/
.header {
  width: 100%;
  left: 0;
  top: 0;
  z-index: 50;
  background-color: #373c4e;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;

  @media (max-width: 420px) {
    background-color: #ffffff;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  color: #ffffff;
  position: relative;
  margin-bottom: 40px;
}
/*------Стилизация блока с кнопкой бургер-меню и формой для поиска в мобильной версии---------------------------------*/
.header-burger-menu {
  position: relative;
  width: 100%;
  display: none;
  flex-direction: row;
  gap: 15px;
  z-index: 1;
  padding: 16px;
}
.header-burger-menu__button {
  width: 32px;
  height: 32px;
  position: relative;
  cursor: pointer;
  background-color: #373c4e;
  border-radius: 100%;
  z-index: 2;
}
.header-burger-menu__button:before {
  content: "";
  position: absolute;
  top: 11px;
  left: 9px;
  background-color: #ffffff;
  width: 13px;
  height: 1px;
}
.header-burger-menu__button span {
  position: absolute;
  top: 15px;
  left: 9px;
  background-color: #ffffff;
  width: 8px;
  height: 1px;
}
.header-burger-menu__button:after {
  content: "";
  position: absolute;
  bottom: 12px;
  left: 9px;
  background-color: #ffffff;
  width: 13px;
  height: 1px;
}
.header-burger-menu__input-search {
  width: 100%;
  height: 32px;
  background-color: rgba(54, 59, 77, 0.05);
  border-radius: 4px;
  outline: none;
  padding: 7px 7px 7px 40px;
  color: rgba(54, 59, 77, 0.6);
}
.header-burger-menu img {
  position: absolute;
  z-index: 5;
  top: 26px;
  left: 79px;
}
/*------Стилизация блока с логотипом----------------------------------------------------------------------------------*/
.header__logotype-block {
  /*display: flex;*/
  /*align-items: center;*/
  display: inline-grid;
  grid-template-columns: 85px 95px 1fr;
  grid-template-rows: 80px;
  align-items: center;

  @media (max-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 992px) {
    grid-template-columns: 65px 70px 1fr;
  }

  @media (max-width: 767px) {
    display: flex;
    gap: 16px;
  }

  @media (max-width: 420px) {
    display: none;
  }
}
.header__logotype-block a {
  text-decoration: none;
  color: #ffffff;
}
.logotype-block__main {
  border-bottom: 4px solid #373c4e;
  text-align: center;
  padding: 28px 0 23px 0;
}
.logotype-block__votes {
  padding: 28px 0 23px 0;
  text-align: center;
  margin-left: 47px;
  border-bottom: 4px solid #373c4e;
}
.logotype-block__main:hover {
  border-bottom: 4px solid #4569ff;
}
.logotype-block__votes:hover {
  border-bottom: 4px solid #4569ff;
}
.logotype-block__main.active {
  border-bottom: 4px solid #4569ff;
}
.logotype-block__votes.active {
  border-bottom: 4px solid #4569ff;
}
/*------Стилизация общего блока с иконками поиск, настройки, личный кабинет-------------------------------------------*/
.header__general-block-search-settings-lk {
  display: flex;
  align-items: center;
  /*gap: 51px;*/
  position: relative;
  gap: 48px;

  @media (max-width: 992px) {
    gap: 15px;
  }
}
/*------Стилизация блока с иконкой поиск------------------------------------------------------------------------------*/
.general-block-search-settings-lk__search {
  display: flex;
  gap: 11px;
  align-items: center;
  cursor: pointer;
  z-index: 3;
  margin: 0 40px 0 0;
}
.general-block-search-settings-lk__search.active {
  display: none;
  cursor: inherit;
}
.general-block-search-settings-lk__search-input {
  position: absolute;
  bottom: -4px;
  right: 230px;
  opacity: 0;
}
.general-block-search-settings-lk__search-input.active {
  transition: all 1s ease;
  opacity: 100;
}
.search-input {
  max-width: 400px;
  height: 30px;
  border-radius: 4px;
  background-color: rgba(101, 105, 117, 0.5);
  outline: none;
  padding: 8px 0 8px 32px;
  color: #ffffff;
  font-size: 14px;

  width: 400px;
}

.header__search {
  position: relative;
  margin-left: 15px;
}

.header__search .search-modal {
  right: auto;
  left: 0;
  top: 50px;
}

.search-input2 {
  display: flex;
  padding: 8px 16px;
  padding-left: 48px;
  background: #373c4e url("../../img/Header_search_icon.png") no-repeat 16px
    center;
  color: #ffffff;
  font-size: 16px;
  height: 40px;
  border-radius: 4px;

  width: 120px;
  will-change: width;
  transition: width 0.3s;
  cursor: pointer;
}

.search-input2::placeholder {
  color: #ffffff;
}

.search-input2_active {
  background-color: rgba(101, 105, 117, 0.5);
  border: none;
  outline: none;
  cursor: text;

  width: 432px;
  @media (max-width: 992px) {
    width: 300px;
  }

  @media (max-width: 767px) {
    width: 170px;
  }
  
}

.search-input2_active::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.search-input-icon {
  position: absolute;
  bottom: 6px;
  left: 10px;
  cursor: pointer;
  width: 16px;
  height: 16px;
}
/*------Стилизация блока с иконкой настройки---------------------------------------------------------------------*/
.general-block-search-settings-lk__settings {
  position: relative;
}
.general-block-search-settings-lk__settings img {
  cursor: pointer;
  padding-top: 3px;
}
/*------Стилизация блока с иконкой личный кабинет---------------------------------------------------------------------*/
.general-block-search-settings-lk__iconclient {
  position: relative;
}
.general-block-search-settings-lk__iconclient span {
  display: flex;
  gap: 11px;
  align-items: center;
  cursor: pointer;
}
/*------Адаптивная версия(992px)--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
  .header {
    font-size: 14px;
  }

  .general-block-search-settings-lk__settings img {
    padding-top: 0;
  }
  .logotype-block__main {
    padding: 31px 0 23px 0;
  }
  .logotype-block__votes {
    padding: 31px 0 23px 0;
  }
}
/*------Адаптивная версия--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {

  .general-block-search-settings-lk__search span {
    display: none;
  }
  .general-block-search-settings-lk__search-input {
    right: 210px;
    bottom: -2px;
  }
  .search-input {
    max-width: 150px;
    padding: 8px 0 8px 26px;
    height: 28px;
  }
  .search-input-icon {
    right: 130px;
    width: 14px;
    height: 14px;
  }
  .logotype-block__votes {
    margin-left: 35px;
  }
}
/*------Адаптивная версия(660px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  .general-block-search-settings-lk__search span {
    display: none;
  }
  .general-block-search-settings-lk__search-input {
    right: 180px;
  }
  .logotype-block__votes {
    margin-left: 15px;
  }
}
/*------Адаптивная версия(520px)--------------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  .header__logotype-block span {
    display: none;
  }
}
@media (max-width: 420px) {
  .search-input {
    max-width: 130px;
  }
  .search-input-icon {
    right: 110px;
    width: 14px;
    height: 14px;
  }
  .general-block-search-settings-lk__search,
  .general-block-search-settings-lk__search-input,
  .general-block-search-settings-lk__settings {
    display: none;
  }
}
/*------Адаптивная версия(375px)--------------------------------------------------------------------------------------*/
@media (max-width: 420px) {
  .header__container {
    display: none;
    min-height: 64px;
    margin-bottom: 8px;
    background-color: #fbfbfc;
  }
  .header__general-block-search-settings-lk {
    display: none;
  }
  .header-burger-menu {
    display: flex;
    align-items: center;
  }
}
