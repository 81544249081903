/*------Контейнер для блока------------------------------------------------------------------------------------------*/
.actual-block-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 24px;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 32px 32px 39px 32px;
    max-width: 488px;
    min-height: 689px;
    box-shadow: 0 3px 16px 0 rgba(54, 58, 72, 0.08);
}
.actual-block-wrapper__title {
    display: flex;
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    color: rgba(54, 59, 77, 0.9);
    gap: 12px;
}

.actual-block__event-name {
    font-size: 18px;
    font-weight: 500;
    line-height: 110%;
    color: rgba(54, 59, 77, 0.9);
    margin: 0 auto 0 0;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 424px;
    min-height: 40px;
}
/*------Блок с датой начала и конца голосования----------------------------------------------------------------------*/
.actual-block__start--end-vote {
    display: flex;
    flex-direction: row;
}
.actual-block__start--end-vote h5 {
    font-size: 14px;
}
.start-end-vote__start-data {
    display: flex;
    flex-direction: column;
    padding: 0 24px 0 0;
    justify-content: center;
    gap: 12px;
    border-right: 1px rgba(54, 59, 77, 0.4) solid;
    font-size: 14px;
}

.start-end-vote__end-data {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 24px;
    justify-content: center;
    gap: 12px;
    font-size: 14px;
}

.actual-block__start--end-vote  h5 {
    opacity: 50%;

}
/*------Блок с кнопнкой проголосовать--------------------------------------------------------------------------------*/
.actual-block__button {
    width: 169px;
    height: 48px;
    background-color: #0084FE;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    cursor: pointer;
    border-radius: 4px;
}
/*------Адаптивная версия---------------------------------------------------------------------------------------------*/
@media (max-width: 1280px) {
    .actual-block-wrapper__title {
        font-size: 21px;
        line-height: 29px;
        font-weight: 500;
    }
}
@media (max-width: 1280px) {
    .actual-block-wrapper {
        padding: 16px 16px 16px 16px;
        max-width: 365px;
        min-height: 570px;
    }
    .actual-block-wrapper__title {
        font-size: 21px;
        line-height: 29px;
        font-weight: 500;
    }
    .actual-block-wrapper h3 {
        font-size: 16px;
        line-height: 24px;
    }
    .actual-block__event-name {
        font-size: 16px;
        line-height: 24px;
        min-height: 48px;
    }
    .start-end-vote__start-data {
        gap: 8px;
        padding: 0 8px 0 0;
    }
    .start-end-vote__end-data {
        gap: 8px;
        padding: 0 0 0 8px;
    }
    .actual-block__button {
        position: absolute;
        bottom: 20px;
        left: 16px;
        width: 311px;
    }
}
/*------Адаптивная версия--------------------------------------------------------------------------------------*/
@media (max-width: 992px) {
    .actual-block-wrapper {
        min-height: 496px;
        gap: 16px;
        max-width: 800px;
    }

    .actual-block__event-name {
        min-height: fit-content;
    }
}
